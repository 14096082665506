*{
  list-style-type: none;
  font-weight: bold;
}
html{
  background-image: linear-gradient(to right,black, grey,lightgrey, white,white,white, white, white, white,white, white, lightgrey, grey, black);
  
}
body{
  
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(https://github.com/wades1248/DM-DB/blob/master/Scan%201.jpeg?raw=true);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center, center;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(31, 68, 45);
  text-align: center;
  list-style-type: none;
  min-height: 100vh;
}
.NavBar{
  margin: 0%;
  height: 7%;
  z-index: 10;
  background-color: rgb(25, 121, 68);
  position: fixed;
  top: 0px;
  color: white;
  font-size: 25px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20%;
  padding-left: 20%;
} 
.NavBar a {
  text-decoration: none;
}
a{
  color: white;
}
.App { 
  padding-bottom: 7%;
  padding-top:7%;
  max-width: 500px;
  margin: 15px;  
  position: relative;
  min-height: 100%;
}

.error{
  color: rgb(165, 21, 21);
}
button{
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgb(51, 111, 151);
  height: 25px;
  margin: 2px;
}
input, select{
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 3px;
}
select{
  border: 1px solid black;
}
li, form, .mainInstrctions{
  color: white;
  background-color: rgba(25, 121, 68, 0.466);
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
  max-width: 500px;
}
.mainInstrctions{
  padding: 10px;
  background-color: rgba(25, 121, 68, 0.753);
  font-weight: normal;
}
.mainInstrctions a{
  display: block;
  width: 100%;
  text-align: center;
}
.details, .mainInstrctions{
  text-align: left;
  margin-left: 15px;
  margin-bottom: 10px;
}
.initiativeDisplay{
  display: block;
  width: 100px;
  margin-top: 5px;

}
input:checked~span{
  color: blue;
}
textarea{
  margin-top: 10px;
}
.initTitle{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
}
.initTitle h3, p{
  display: block;
  margin-bottom: 0px;
  margin-top: 0px;
}
.heading{
  display: flex;
}
.hp{
  width: 30px;
}
.instructions{
  margin-bottom: 30px;
}
legend{
  font-size: 20px;
}
.nav{
  height: 35px;
}
@media screen and (max-width: 768px){
  body{
    background-image: none;
    background-color: white;
  }
  #root{
    background-image: none;
  }
  .NavBar{
    font-size: 15px;
  }
}
